<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Buddies" subtitle="Keep in touch with your friends and see what they're playing." :tabs="tabs" >

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" :min-length="3" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-call="!collection.length && !isSearching" :is-empty="!collection.length && isSearching && !is.loading" placeholder="No buddies found, try another search." :is-grid="collection.length && isSearching">

		<app-content-call v-if="!isSearching" v-on:search="onSearch" title="Search the directory" search="Enter name or e-mail and press enter..." icon="search" text="You can search by name or e-mail to quickly find other attendees that you know." />

		<app-buddy v-for="item in collection" :key="item.key" :buddy="item" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: false,
			endpoint: 'convention/buddies/directory'
		}

	},

	computed: {

		isSearching: function() {

			return this.filter.search || this.collection.length

		},

		tabs: function() {

			return [
				{ name: 'Convention.Buddies', text: 'Buddies' },
				{ name: 'Convention.Buddies.Suggested', text: 'Suggested' },
				{ name: 'Convention.Buddies.Directory', text: 'Directory' }
			]

		}

	},

	methods: {

		onSearch: function(text) {

			if (text.length > 2) {

				this.filter.search = text

			}

		}

	}

}

</script>

<style scoped>

</style>